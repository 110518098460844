a.single_megamenu img {

    max-width: 200px;
    max-height: 173px;
    min-height: 173px;
}

.white-paper-mega-menu {
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    gap: 5px;
    /* background-color: red; */
    /* height: 700px; */
    overflow: auto;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-right: 6rem;
  max-height: 65vh; /* تحديد الحد الأقصى لارتفاع الحاوية */
  overflow-y: auto; /* إضافة التمرير عند الحاجة */
}

.white-paper-menu-item {
  flex: 1 1 calc(20% - 10px); /* جعل كل كارت يأخذ 20% من العرض الكلي مع مساحة بين الكروت */
  box-sizing: border-box;
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #fff;
  text-align: center;
}

.white-paper-menu-item figure {
  margin: 0;
  padding: 0;
  height: 150px;
  overflow: hidden;
}

.white-paper-menu-item img {
  max-width: 100%;
  height: auto;
}


.white-paper-mega-menu.equipment-dropdown .white-paper-menu-item {
    width: calc(16.7% - 5px);
}

.white-paper-mega-menu .white-paper-menu-item {

    text-align: center;
    border-radius: 10px;

    position: relative;
    background: white;
    overflow: hidden;
}

.white-paper-mega-menu .white-paper-menu-item::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 7px;
    background: #fba928;
    border-radius: 0 0 5px 5px;
    
}

.an-machine-amount {
    position: absolute;
    width: 30px;
    height: 30px;
    font-size: 12px;
    font-weight: 700;
    background: #fba928;
    line-height: 30px;
    border-radius: 100%;
    right: 5px;
    top: 5px;
    color: black;
}

.white-paper-mega-menu .white-paper-menu-item figure {
    margin-bottom: 0px;
    background: white;
    height: 133px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.white-paper-mega-menu .white-paper-menu-item figure img {
    max-width: 130px;
    max-height: 110px;
}

.white-paper-mega-menu .white-paper-menu-item h2 {
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0px;
    padding: 13px 0px;
    color: black;
}

.our-stock-menus .dropdown-content {
    background: white;
    padding: 15px;
    color: black;
    max-height: 620px;
    overflow: auto;
}

.dropdown-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.our-stock-menus .dropdown-content h3 {
    color: black;
}

.dropdown-heading h3 {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.dropdown-heading p {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 600;
}

.an-theme-btn.outline.black {
    color: black;
    border-color: black;
}

.dropdown-heading .an-theme-btn {
    font-weight: 700;
    font-size: 13px;
}

.an-theme-btn.outline {
    border: 1px solid white;
    padding: 10px 20px;
    border-radius: 6px;
}

.dropdown-sub-heading {
    font-size: 16px;
    font-weight: 700;
    position: relative;
    padding-bottom: 5px;
    /* margin-bottom: 10px; */
    margin-top: 15px;
    text-transform: uppercase;
}

.dropdown-sub-heading::before {
    content: "";
    position: absolute;
    width: 60px;
    height: 3px;
    background: #fba928;
    bottom: 0px;
    left: 0px;
}

.an-theme-btn.outline.black:hover {
    border-color: #fba928;
    background: #fba928;
}

.dropdown-sub-heading i {
    font-size: 21px;
    color: #fba928;
    position: relative;
    bottom: -2px;
}

.equ-table-row {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    padding: 5px 0px;
    border-bottom: 1px solid #b5b5b5;
    font-weight: 600;
}

.equ-table-row .colum-1 {
    width: 35%;
}

.equ-table-row .colum-2 {
    width: 15%;
}

.equ-table-row .colum-3 {
    width: 15%;
}

.equ-table-row .colum-4 {
    width: 15%;
}

.equ-table-row .colum-5 {
    width: 20%;
}

.our-stock-menus .dropdown-content a {
    color: black;
    font-weight: 600;
}

.mx-50 {
    margin-left: 170px;
}

.white-paper-mega-menu .white-paper-menu-item .hover {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    background: hsl(42.35deg 100% 50% / 58.04%);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 15px;
    transition: 0.5s;
    opacity: 0;
}

.white-paper-mega-menu .white-paper-menu-item .hover span {
    display: inline-block;
    font-size: 9px;
    text-transform: uppercase;
    color: white;
    font-weight: 600;
    background: black;
    padding: 7px 20px;
    border-radius: 5px;
    transform: translateY(10px);
    transition: 0.5s;
}

.white-paper-mega-menu .white-paper-menu-item:hover .hover {
    opacity: 1;
    transform: scale(1);
}

.services-img {
    width: 240px !important;
    /* width: 300px; */
    height: 180px;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
        display: flex !important;

        flex-wrap: wrap;

    }
}

@media (max-width: 992px) {
    .mx-50 {
        margin-left: 0px;
    }

    .white-paper-mega-menu.equipment-dropdown .white-paper-menu-item {
        width: 100%;
         
    }

    .services-img {
        margin-top: 5px;
        width: 100% !important;
        /* width: 300px; */
        height: 135px;
    }
}

@media (max-width: 767px) {
    .services-img {
        margin-top: 5px;
        width: 100% !important;
        /* width: 300px; */
        height: 160px;
    }
}